import uuid from "uuid/v4";
import {
  ADD_TO_CART,
  DECREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART,
  ADD_SMARTBOOK_TO_CART,
} from "../actions/cartActions";

const initState = [];

const _addSmartBookToCart = (cartItems, product) => {
  return [
    ...cartItems,
    {
      ...product,
      quantity: product.quantity ? product.quantity : 1,
      cartItemId: uuid(),
      smartbook: true,
    },
  ];
};

const cartReducer = (state = initState, action) => {
  const cartItems = state;
  const product = action.payload;

  if (action.type === ADD_SMARTBOOK_TO_CART) {
    return _addSmartBookToCart(state, product);
  }

  if (action.type === ADD_TO_CART) {
    // for non variant products
    const { selectedProductColor, selectedProductMaterial } = product;

    if (!selectedProductColor && !selectedProductMaterial) {
      const cartItem = cartItems.filter((item) => item._id === product._id)[0];
      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid(),
          },
        ];
      }
      return cartItems.map((item) =>
        item.cartItemId === cartItem.cartItemId
          ? {
              ...item,
              quantity: product.quantity
                ? item.quantity + product.quantity
                : item.quantity + 1,
            }
          : item
      );

      // for variant products
    }
    const cartItem = cartItems.filter((item) => {
      return (
        item._id === product._id &&
        (selectedProductColor !== undefined
          ? selectedProductColor === item.selectedProductColor
          : true) &&
        (selectedProductMaterial !== undefined
          ? selectedProductMaterial === item.selectedProductMaterial
          : true)
      );
    })[0];

    if (cartItem === undefined) {
      return [
        ...cartItems,
        {
          ...product,
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: uuid(),
        },
      ];
    }
    if (
      cartItem !== undefined &&
      (cartItem.selectedProductColor !== selectedProductColor ||
        cartItem.selectedProductMaterial !== selectedProductMaterial)
    ) {
      return [
        ...cartItems,
        {
          ...product,
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: uuid(),
        },
      ];
    }
    return cartItems.map((item) =>
      item.cartItemId === cartItem.cartItemId
        ? {
            ...item,
            quantity: product.quantity
              ? item.quantity + product.quantity
              : item.quantity + 1,
            selectedProductColor: product.selectedProductColor,
            selectedProductMaterial: product.selectedProductMaterial,
          }
        : item
    );
  }

  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          (cartItem) => cartItem.cartItemId !== product.cartItemId
        );
      return remainingItems(cartItems, product);
    }
    return cartItems.map((item) =>
      item.cartItemId === product.cartItemId
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
  }

  if (action.type === DELETE_FROM_CART) {
    const remainingItems = (cartItems, product) =>
      cartItems.filter(
        (cartItem) => cartItem.cartItemId !== product.cartItemId
      );
    return remainingItems(cartItems, product);
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return cartItems.filter((item) => false);
  }

  return state;
};

export default cartReducer;
