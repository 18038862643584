export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const ADD_SMARTBOOK_TO_CART = "ADD_SMARTBOOK_TO_CART";

// Añadir al Carrito
export const addToCart = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductMaterial
) => (dispatch) => {
  if (addToast) {
    addToast("Añadido al carrito", {
      appearance: "success",
      autoDismiss: true,
    });
  }
  dispatch({
    type: ADD_TO_CART,
    payload: {
      ...item,
      quantity: quantityCount,
      selectedProductColor,
      selectedProductMaterial,
    },
  });
};
// decrease from cart
export const decreaseQuantity = (item, addToast) => (dispatch) => {
  if (addToast) {
    addToast("Se elimino el producto del carrito", {
      appearance: "warning",
      autoDismiss: true,
    });
  }
  dispatch({ type: DECREASE_QUANTITY, payload: item });
};
// delete from cart
export const deleteFromCart = (item, addToast) => (dispatch) => {
  if (addToast) {
    addToast("Se elimino el producto del carrito", {
      appearance: "error",
      autoDismiss: true,
    });
  }
  dispatch({ type: DELETE_FROM_CART, payload: item });
};
// delete all from cart
export const deleteAllFromCart = (addToast) => (dispatch) => {
  if (addToast) {
    addToast("Se eliminarón todos los productos del carrito", {
      appearance: "error",
      autoDismiss: true,
    });
  }
  dispatch({ type: DELETE_ALL_FROM_CART });
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  }
  if (item.variation) {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].stock;
  }
  return 5000;
};
